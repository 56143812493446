import styled from "styled-components";
import "@fontsource/poppins";

const Container = styled.div`
  height: 100vh;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 250px;
`;

const TableHeader = styled.th`
  text-align: center;
`;

const TableRow = styled.tr`
  text-align: center;
`;

const SideBar = (Locations, { FlyToMap }) => {
  return (
    <Container>
      <Table>
        <tbody>
          <TableRow>
            <TableHeader>Known Locations</TableHeader>
          </TableRow>
          {Locations.Locations.map((knownLocation) => {
            if (
              knownLocation.geometry.coordinates[0] !== 0 &&
              knownLocation.geometry.coordinates[1] !== 0 /*&&
            knownLocation.properties.location_name
              .toLowerCase()
              .startsWith("ows")*/
            ) {
              return (
                <TableRow key={knownLocation.properties.location_id}>
                  <button
                    onClick={() =>
                      FlyToMap(
                        knownLocation.geometry.coordinates[0],
                        knownLocation.geometry.coordinates[1],
                        15
                      )
                    }
                  >
                    {knownLocation.properties.location_name}
                  </button>
                </TableRow>
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </Table>
      <Table>
        <tbody>
          <TableRow>
            <TableHeader>Unknown Locations</TableHeader>
          </TableRow>
          {Locations.Locations.map((UnknownLocation) => {
            if (
              UnknownLocation.geometry.coordinates[0] === 0 &&
              UnknownLocation.geometry.coordinates[1] === 0 /*&&
            UnknownLocation.properties.location_name
              .toLowerCase()
              .startsWith("ows")*/
            ) {
              return (
                <TableRow key={UnknownLocation.properties.location_id}>
                  {UnknownLocation.properties.location_name}
                </TableRow>
              );
            } else {
              return null;
            }
          })}
        </tbody>
      </Table>
    </Container>
  );
};

export default SideBar;
