import "./Map.css";
import styled from "styled-components";
import React, { useState, useRef } from "react";
import useSwr from "swr";
import { RiSailboatLine } from "react-icons/ri";
import ReactMapGL, { Marker, Popup } from "react-map-gl";
import useSupercluster from "use-supercluster";
import SideBar from "./SideBar";

const fetcher = (...args) => fetch(...args).then((response) => response.json());

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

const OwsPopupTitle = styled.h2`
  margin: 0;
`;

const OwsIconContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const OwsIconText = styled.p`
  margin: 0;
`;

const Map = () => {
  const [viewport, setViewport] = useState({
    latitude: 50,
    longitude: 0,
    width: "100%",
    height: "100vh",
    zoom: 4,
  });

  const [popupInfo, setPopupInfo] = useState(null);

  const mapRef = useRef();

  const { data, error } = useSwr(
    [
      "https://ows.weatherfile.com/api/admin/locations_menu",
      { headers: { "wf-tkn": "c9ulmunqt2sulg93dab0" } },
    ],
    fetcher,
    { refreshInterval: 0 }
  );

  const ows = data && !error ? data : { data: [] };

  const points = ows.data.map((ows) => ({
    type: "Feature",
    properties: {
      cluster: false,
      location_id: ows.location_id,
      location_name: ows.location_name,
      friendly_description: ows.friendly_description,
      friendly_location: ows.friendly_location,
      lastcontact: ows.lastcontact,
    },
    geometry: {
      type: "Point",
      coordinates: [ows.lng, ows.lat],
    },
  }));

  const bounds = mapRef.current
    ? mapRef.current.getMap().getBounds().toArray().flat()
    : null;

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: viewport.zoom,
    options: { radius: 50, maxZoom: 20 },
  });

  function FlyToMap(lat, lng, zoom) {
    mapRef.current?.flyTo({
      center: [lng, lat],
      zoom: zoom,
      duration: 2000,
    });
  }

  return (
    <Container>
      {/*points.length > 0 ? (
        <SideBar Locations={points} FlyToMap={{ FlyToMap }} />
      ) : null*/}
      <ReactMapGL
        {...viewport}
        maxZoom={20}
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onMove={(newViewport) => {
          setViewport({ ...newViewport.viewState });
        }}
        mapStyle="mapbox://styles/mapbox/streets-v9"
        ref={mapRef}
      >
        {clusters.map((cluster) => {
          if (
            cluster.geometry.coordinates[0] !== 0 &&
            cluster.geometry.coordinates[1] !== 0
          ) {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } =
              cluster.properties;

            if (isCluster) {
              return (
                <Marker
                  key={`cluster-${cluster.id}`}
                  latitude={latitude}
                  longitude={longitude}
                >
                  <div
                    className="cluster-marker"
                    style={{
                      width: `${10 + (pointCount / points.length) * 20}px`,
                      height: `${10 + (pointCount / points.length) * 20}px`,
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        supercluster.getClusterExpansionZoom(cluster.id),
                        20
                      );
                      mapRef.current?.flyTo({
                        center: [longitude, latitude],
                        zoom: expansionZoom,
                        duration: 2000,
                      });
                      setViewport({
                        ...viewport,
                      });
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }

            return (
              <>
                <Marker
                  key={`crime-${cluster.properties.location_id}`}
                  latitude={latitude}
                  longitude={longitude}
                  onClick={(e) => {
                    e.originalEvent.stopPropagation();
                    setPopupInfo(cluster);
                  }}
                >
                  <OwsIconContainer>
                    <OwsIconText>
                      {cluster.properties.location_name}
                    </OwsIconText>

                    <RiSailboatLine size={30} />
                  </OwsIconContainer>
                </Marker>
                {popupInfo ? (
                  <Popup
                    anchor="bottom"
                    longitude={Number(popupInfo.geometry.coordinates[0])}
                    latitude={Number(popupInfo.geometry.coordinates[1])}
                    onClose={() => setPopupInfo(null)}
                  >
                    <OwsIconContainer>
                      <OwsPopupTitle>
                        {popupInfo.properties.location_name}
                      </OwsPopupTitle>
                      <p>Last Contact: {popupInfo.properties.lastcontact}</p>
                    </OwsIconContainer>
                  </Popup>
                ) : null}
              </>
            );
          } else {
            return null;
          }
        })}
      </ReactMapGL>
    </Container>
  );
};

export default Map;
